import * as Yup from 'yup';
import 'config/yupExtensions';
import Translator from 'components/ErrorTranslator';

export const vatNumber = (_isMandatory: boolean) =>
  Yup.string()
    .matches(/^CZ(\d{8,10})$/, {
      message: Translator(
        'company_info.form.vatNumber.ruleErrorMessage',
        'bad format (e.g CZ12345678 or CZ1234567899)',
      ),
      excludeEmptyString: true,
    })
    .when('vatPayer', {
      is: true,
      then: Yup.string().required(
        Translator(
          'company_info.form.vatNumber.ruleErrorMessage',
          'bad format (e.g CZ12345678 or CZ1234567899)',
        ),
      ),
    });

export const activity = (isMandatory: boolean) =>
  Yup.array()
    .of(Yup.string())
    .isMandatory(
      isMandatory,
      Translator(
        'company_info.form.activity.ruleErrorMessage',
        'At least one service is required',
      ),
    );
