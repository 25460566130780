import Translator from 'components/ErrorTranslator';
import {
  BelgiumValidationContext,
  PaymentOptionEnum,
  PaymentTypeCodeEnum,
} from 'components/Onboarding/types';
import { LocationTypeEnum } from 'generated/models';
import * as Yup from 'yup';
import { ArraySchema, StringSchema } from 'yup';
import 'config/yupExtensions';
import * as ADDRESS from './address';
import { EMAIL, STRING } from './common';

const mapOptionToType: Record<PaymentOptionEnum, PaymentTypeCodeEnum[]> = {
  [PaymentOptionEnum.Marketplace]: [PaymentTypeCodeEnum.Marketplace],
  [PaymentOptionEnum.Payconiq]: [PaymentTypeCodeEnum.Payconiq],
  [PaymentOptionEnum.POS]: [PaymentTypeCodeEnum.Terminal],
  [PaymentOptionEnum.PSP]: [PaymentTypeCodeEnum.PSP],
};

const ifThen = (
  requiredType: LocationTypeEnum,
  schema: Yup.BaseSchema,
  thenSchema: Yup.BaseSchema,
  otherwiseSchema?: Yup.BaseSchema,
) =>
  schema.when('type', {
    is: (type: string) => type === requiredType,
    then: thenSchema,
    otherwise: otherwiseSchema,
  });

const ifPaymentOption = (
  option: PaymentOptionEnum,
  schema: Yup.BaseSchema,
  thenSchema: Yup.BaseSchema,
) =>
  schema.when(['paymentOptions', '$isPaymentOptionEnabled'], {
    is: (
      paymentOptions: PaymentOptionEnum[],
      isPaymentOptionEnabled: (code: PaymentTypeCodeEnum) => boolean,
    ) =>
      paymentOptions.includes(option) &&
      mapOptionToType[option].some((t) => isPaymentOptionEnabled(t)),
    then: thenSchema,
  });

export const ifOnlineThen = (
  schema: Yup.BaseSchema,
  thenSchema: Yup.BaseSchema,
  otherwiseSchema?: Yup.BaseSchema,
) => ifThen(LocationTypeEnum.Online, schema, thenSchema, otherwiseSchema);

export const ifPhysicalThen = (
  schema: Yup.BaseSchema,
  thenSchema: Yup.BaseSchema,
  otherwiseSchema?: Yup.BaseSchema,
) => ifThen(LocationTypeEnum.Physical, schema, thenSchema, otherwiseSchema);

export const ifMarketplaceThen = (
  schema: Yup.BaseSchema,
  thenSchema: Yup.BaseSchema,
  otherwiseSchema?: Yup.BaseSchema,
) => ifThen(LocationTypeEnum.Marketplace, schema, thenSchema, otherwiseSchema);

export const WEB = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory)
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      {
        excludeEmptyString: !isMandatory,
        message: Translator('location_add.form.website.ruleErrorMessage', 'Invalid URL format'),
      },
    );

export const beWebshopEmail = (isMandatory: boolean) =>
  ifPhysicalThen(
    Yup.string(),
    Yup.string().nullable(),
    EMAIL(isMandatory, 'location_add.form.email.ruleErrorMessage'),
  );

// TODO: handle Marketplace
export const beWeb = (isMandatory: boolean) =>
  ifMarketplaceThen(
    Yup.string(),
    Yup.string().nullable(),
    ifOnlineThen(Yup.string(), WEB(isMandatory), WEB(false)),
  );

export const bePhysicalStreetName = (isMandatory: boolean) =>
  ifPhysicalThen(Yup.string(), ADDRESS.streetName(isMandatory));

export const bePhysicalHouseNumber = (isMandatory: boolean) =>
  ifPhysicalThen(Yup.string(), ADDRESS.houseNumber(isMandatory));

export const bePhysicalCity = (isMandatory: boolean) =>
  ifPhysicalThen(Yup.string(), STRING(isMandatory));

export const bePhysicalPostalCode = (isMandatory: boolean) =>
  ifPhysicalThen(Yup.string(), ADDRESS.bePostalCode(isMandatory));

export const beTerminals = (name: string, schema: Yup.BaseSchema) =>
  Yup.object().shape({
    [name]: ifPaymentOption(
      PaymentOptionEnum.POS,
      Yup.array(),
      Yup.array()
        .of(schema)
        .isMandatory(
          true,
          Translator(
            'location_add.form.location_add.form.terminalRequiredValidation',
            'Fill at least one payment terminal',
          ),
        )
        .max(
          10,
          Translator(
            'location_add.form.location_add.form.terminalLimitValidation',
            'Only up to 10 terminals is allowed',
          ),
        ),
    ),
  });

export const beOnlineTerminalProvider = (isMandatory: boolean) =>
  ifPaymentOption(PaymentOptionEnum.PSP, Yup.string(), Yup.string().isMandatory(isMandatory));

export const beOnlineTerminalId = (isMandatory: boolean) =>
  ifPaymentOption(PaymentOptionEnum.PSP, Yup.string(), Yup.string().isMandatory(isMandatory));

export const bePaymentOptions = (isMandatory: boolean) =>
  (Yup.array().of(Yup.string()) as ArraySchema<StringSchema, BelgiumValidationContext>).test(
    'Has enabled options',
    Translator(
      'location_add.sectionTexts.paymentOptions.subheader',
      'Select one or multiple payment options for your location',
    ),
    function (value, { options: { context } }) {
      const isPaymentOptionEnabled = context?.isPaymentOptionEnabled;
      if (isPaymentOptionEnabled && value) {
        return value.some((option) =>
          mapOptionToType[option as PaymentOptionEnum].some((type) => isPaymentOptionEnabled(type)),
        );
      }
      return !isMandatory;
    },
  );

export const beAcceptedProducts = (isMandatory: boolean) =>
  (Yup.array().of(Yup.string()) as ArraySchema<StringSchema, BelgiumValidationContext>).test(
    'Has enabled options',
    Translator(
      'location_add.form.product_required_validation',
      'Please select at least one product',
    ),
    function (value, { options: { context } }) {
      const isProductEnabled = context?.isProductEnabled;
      if (isProductEnabled && value) {
        return value.some((product) => isProductEnabled(product as string));
      }
      return !isMandatory;
    },
  );
